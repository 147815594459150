import { Button } from "../button";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { ConsumerApi } from "../ConsumerApi.dto";
import { useSetRecoilState } from "recoil";
import { showReferFriendsState } from "atoms";
import DisplayPoints from "./DisplayPoints";

type FriendReferralsProps = {
  rewardsStatus: ConsumerApi.GetRewardsStatusResponse;
};

const FriendReferrals: React.FC<FriendReferralsProps> = (props) => {
  const { t } = useTranslation();

  const setShowReferFriends = useSetRecoilState(showReferFriendsState);

  return (
    <>
      <DisplayPoints
        title={t("Invite Friends")}
        description={
          <Trans>
            Invite Friends to use Brovs and you earn Reward Points when they
            Sign Up as a new Brovs User. You can Invite as many Friends as you
            like.
          </Trans>
        }
        points={props.rewardsStatus.referFriend.points}
      />
      <div className="mt-[30px]">
        <Button
          onClick={() => setShowReferFriends(true)}
          title={t("Invite Friends")}
        />
      </div>
    </>
  );
};

export default FriendReferrals;
