import { apiServiceState, loadingState } from "../atoms";
import { ConsumerApi } from "../ConsumerApi.dto";
import React, { useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { sendSMS } from "../helpers/ShareHelper";
import { AdMessageModel } from "../model";
import { Overlay } from "../overlay";
import { Button } from "button";

type ShareAdMessageProps = {
  adMessage: AdMessageModel;
  onClose: () => void;
};

export const ShareAdMessage: React.FC<ShareAdMessageProps> = (props) => {
  const { t } = useTranslation();

  const setLoading = useSetRecoilState(loadingState);
  const apiService = useRecoilValue(apiServiceState);

  const share = useCallback(
    (sms?: boolean) => {
      setLoading(true);

      apiService
        .get(
          new ConsumerApi.ShareAdMessageLinkRequest({
            adMessageId: props.adMessage.dto.id,
          }),
        )
        .then((res) => {
          const message = t(
            `Hi :-)\nYou might have an interest in this Ad; “{{adTitle}}” from “{{placeName}}”. View in Brovs.com, the new website to browse for Ads, Products and Services at Local Businesses. Fashion - Food & Drink - Rewards - and more.`,
            {
              adTitle: props.adMessage.title,
              adDescription: props.adMessage.description,
              placeName: props.adMessage.dto.place.name,
            },
          );
          const url = res.linkWithShareAdMessageToken;

          if (sms) {
            sendSMS(`${message}\n${url}`);
          } else {
            navigator.share({
              title: props.adMessage.title,
              text: message,
              url: res.linkWithShareAdMessageToken,
            });
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    },
    [
      apiService,
      props.adMessage.description,
      props.adMessage.title,
      setLoading,
      t,
    ],
  );

  return (
    <Overlay onClose={props.onClose}>
      <div className="flex flex-col items-center">
        <div>
          <svg
            width="58"
            height="58"
            viewBox="0 0 58 58"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M57 29C57 44.464 44.464 57 29 57C13.536 57 1 44.464 1 29C1 13.536 13.536 1 29 1C44.464 1 57 13.536 57 29Z"
              stroke="#D2D2D2"
              strokeWidth="2"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M20.6471 25.7351L39 19V38.7647L27.0731 34.4906L24.9593 40.1765H19.2353L21.934 32.649L20.6471 32.1878V25.7351ZM19.2353 31.7059H15V26.0588H19.2353V31.7059Z"
              fill="currentColor"
            />
          </svg>
        </div>
        <div className="font-bold text-h7 text-center mt-[25px]">
          <div>
            <Trans>Share Ad</Trans>
          </div>
        </div>
        <div style={{ marginTop: "10px" }} className="center text-body">
          <Trans>
            You will receive Brovs Reward Points when the Ad is viewed by people
            you shared it with.
            <br />
            <br />
            You can use Reward Points in the Brovs Lottery.
            <br />
            <br />
            Share with as many people as you like.
          </Trans>
        </div>
        <div className="mt-[25px] w-full">
          <Button
            onClick={() => {
              props.onClose();
              share(true);
            }}
            title={t("Share With SMS")}
          />
        </div>
        {/* TODO: Hide until we have tested and fixed the preview in Twitter, FB and other media. */}
        {/* {navigator.share !== undefined ?
          <button
            onClick={() => share()}
            style={{marginTop: "15px"}}
            className="bg-blue text-white style-subtitle h-11 w-full rounded">
            {t("Other")}
          </button>
        : null} */}
      </div>
    </Overlay>
  );
};
