{
  "Starts": "Starter",
  "Ends": "Slutter",
  "View Ad": "Se Annonse",
  "More Information": "Mer Informasjon",
  "View": "Se på",
  "Bookmark your Search": "Bokmerk dette Søket",
  "Bookmark and name your search result for easy access later. You will find the bookmarked searches in the main menu.": "Legg til bokmerke og navngi søkeresultatet. Du finner bokmerkede søk i hovedmenyen.",
  "Name your search result": "Gi ditt søkeresultat et navn",
  "Save Bookmark": "Lagre Bokmerke",
  "Bookmarks": "Bokmerker",
  "Save": "Lagre",
  "Edit": "Redigere",
  "Search name": "Søk navn",
  "Keep Bookmark": "Behold Bokmerke",
  "Remove Bookmark": "Fjern Bokmerke",
  "Current Location": "Nåværende Plassering",
  "Change Location": "Bytt Sted",
  "Search Location": "Søk Sted",
  "Warning Press Allow!": "Advarsel Trykk på Tillat!",
  "To browse Ads, Products and Services at your Current Location you need to Tap “Allow” in the next screen when asked for Current Location.": "For å bla gjennom annonser, produkter og tjenester på din nåværende plassering, må du trykke på \"Tillat\" på neste skjermbilde når du blir spurt om nåværende plassering.",
  "To browse Ads, Products and Services at your Current Location you need to Tap ”Allow” in the next screen when asked for Device Location.": "For å bla gjennom annonser, produkter og tjenester på din nåværende plassering, må du trykke på \"Tillat\" i neste skjermbilde når du blir spurt om enhetsplassering.",
  "OK": "OK",
  "Your Current Location is not available": "Din nåværende posisjon er ikke tilgjengelig",
  "To browse Ads, Products and Services at your Current Location you need to enable Location Services.": "For å bla gjennom annonser, produkter og tjenester på din nåværende posisjon, må du aktivere posisjonstjenester.",
  "Enable Location": "Skru på Stedstjenester",
  "Ok": "Ok",
  "Open Settings on Your Phone": "Åpne Innstillinger på telefonen",
  "Tap Privacy & Security": "Trykk på Personvern og sikkerhet",
  "Privacy & Security": "Personvern og sikkerhet",
  "Tap Location Services": "Trykk på Stedstjenester",
  "Location Services": "Stedstjenester",
  "Off": "Avslå",
  "Activate: Location Services": "Aktiver: Stedstjenester",
  "Scroll Down and Tap on Safari Websites": "Rull ned og trykk på Safari-nettsteder",
  "Safari Websites": "Safari-nettsteder",
  "Never": "Aldri",
  "Activate: While Using the App": "Aktiver: Mens du bruker appen",
  "While Using the App": "Når appen er i bruk",
  "Activate: Precise Location": "Aktiver: Nøyaktig plassering",
  "Precise Location": "Nøyaktig Posisjon",
  "Open Phone Settings": "Åpne Innstillinger",
  "Scroll Down and Tap Safari": "Rull ned og trykk på Safari",
  "Safari": "Safari",
  "Scroll Down and Tap Location": "Rull ned og trykk på Sted",
  "Location": "Sted",
  "When displaying:": "Når du viser:",
  "LOCATION ACCESS ON ALL WEBSITES": "TILGANG TIL STED PÅ ALLE NETTSIDER",
  "Then activate: Allow": "Aktiver deretter: Tillat",
  "Allow": "Tillat",
  "OR": "ELLER",
  "If it has the entry below:": "Hvis den har oppføringen nedenfor:",
  "www.brovs.com": "www.brovs.com",
  "Activate: Allow": "Aktiver: Tillat",
  "Tap Location": "Trykk på Sted",
  "Enable: Location": "Aktiver: Plassering",
  "Tap App permissions": "Trykk på Apptillatelser",
  "App permissions": "App-tillatelser",
  "Scroll Down and Tap Chrome": "Rull ned og trykk på Chrome",
  "Chrome": "Chrome",
  "Allow all the time": "Tillat hele tiden",
  "Allow while using the app": "Tillat mens du bruker appen",
  "Open Chrome on Your Phone": "Åpne Chrome på telefonen",
  "Type brovs.com in the Chrome (URL)": "Skriv inn brovs.com i Chrome (URL)",
  "brovs.com": "brovs.com",
  "Tap Site Settings Icon": "Trykk på ikonet for nettstedinnstillinger",
  "Tap Permissions": "Trykk på Tillatelser",
  "Permissions": "Tillatelser",
  "Allowed": "Tillatt",
  "Enable Location for Safari": "Aktiver plassering for Safari",
  "Enable Location for Chrome": "Aktiver posisjon for Chrome",
  "Enable Location for Website": "Aktiver plassering for nettstedet",
  "Well Done!": "Godt gjort!",
  "Please make sure to enable Location Services for Safari and Website so you can browse at your Current Location. Follow the guide below.": "Sørg for å aktivere posisjonstjenester for Safari og nettsted slik at du kan surfe på din nåværende posisjon. Følg veiledningen nedenfor.",
  "Please make sure to enable Location Services for Chrome and Website so you can browse at your Current Location. Follow the guide below.": "Sørg for å aktivere posisjonstjenester for Chrome og nettstedet slik at du kan surfe på din nåværende posisjon. Følg veiledningen nedenfor.",
  "That was a chore, but now you can enjoy BROVS and browse Ads, Products and Services at your Current Location.": "Det var et ork, men nå kan du nyte BROVS og bla gjennom annonser, produkter og tjenester på din nåværende plassering.",
  "Back": "Tilbake",
  "Next": "Neste",
  "Done": "Utført",
  "Invalid Link. Contact Customer Service.": "Ugyldig lenke. Kontakt kundeservice.",
  "Verified E-mail": "Verifisert e-post",
  "Your E-mail {{email}} has been verified.": "E-posten din {{email}} er bekreftet.",
  "Are you sure you want to Unbookmark": "Er du sikker på at du vil fjerne bokmerket",
  "Confirm": "Bekreft",
  "Accept to create a profile.": "Godta for å opprette en profil.",
  "Create Profile": "Lag Profil",
  "To BROVS ads, products and services you need to create a Profile.": "For å BROVS annonser, produkter og tjenester må du opprette en profil.",
  "Gender *": "Kjønn *",
  "Female": "Dame",
  "Male": "Mann",
  "Create Profile Now": "Lag Profil Nå",
  "Required": "Obligatorisk",
  "Invalid E-mail.": "Ugyldig E-post.",
  "Minimum password length is 8 characters.": "Minste passordlengde er 8 tegn.",
  "Repeat Password doesn't match.": "Repeat Password samsvarer ikke.",
  "Accept to create a Profile.": "Godta for å opprette en Profil.",
  "Address is required.": "Adresse er påkrevd.",
  "First Name": "Fornavn",
  "First Name *": "Fornavn *",
  "Last Name": "Etternavn",
  "Last Name *": "Etternavn *",
  "Mobile Number *": "Mobilnummer *",
  "Continue": "Fortsett",
  "Type your E-mail": "Skriv inn din E-post",
  "E-mail": "E-post",
  "Don't have an E-mail?": "Har du ikke en E-post?",
  "Type Password": "Skriv inn Passord",
  "Create Password *": "Lag Passord *",
  "Repeat Password": "Gjenta Passord",
  "Home Location *": "Hjemsted *",
  "Home address is used to display nearby Businesses and Ads.": "Hjemmeadresse brukes til å vise virksomheter og annonser i nærheten.",
  "Are you sure you want to cancel Sign Up?": "Er du sikker på at du vil avbryte registreringen?",
  "Verify Mobile Number": "Bekreft Mobilnummer",
  "You will receive a SMS with a Short Code to verify your Mobile Number.": "Du vil motta en SMS med en kortkode for å bekrefte mobilnummeret ditt.",
  "Mobile Number": "Mobilnummer",
  "Is your Mobile Number below correct?": "Er ditt Mobilnummer nedenfor riktig?",
  "Edit if not correct.": "Rediger hvis det ikke er riktig.",
  "Check your SMS for Short Code.": "Sjekk SMS -en din for kortkode.",
  "Short Code": "Kortkode",
  "Type Short Code": "Skriv Kortkode",
  "Didn't receive a Short Code?": "Mottok du ikke en Kortkode?",
  "Resend Code": "Send Kode på nytt",
  "Save Food & Money": "Spar Mat og Penger",
  "Ads": "Annonser",
  "Places": "Steder",
  "Searches": "Søk",
  "End Of Day Unsold Food And Beverages": "Slutt på dagen Usolgt mat og drikke",
  "All Categories": "Alle Kategorier",
  "Choose <1>Brands</1> from the list below.": "Velg <1>merker</1> fra listen under.",
  "Filters": "Filtre",
  "Apply": "Legg til",
  "Search Brands": "Søk etter Merker",
  "Brands": "Merker",
  "Filter by <1>Brands</1> only to view nearby Brands. Filter by <3>Merchandise</3> only to view nearby Merchandise. You can combine Brands and Merchandise for a more specific search.<br/>Tip: Choose one combination for best result.": "Filtrer etter <1>Merker</1> for å se kun nærliggende Merkevarer. Filtrer etter <3>Varer</3> for å se kun nærliggende varer. Du kan kombinere Merker og Varer for et mer spesifikt søk.<br/>Tips: Velg én kombinasjon for best resultat.",
  "Filter Ads for end of day unsold food and beverages. Avoid wasting perfectly good food, save money and support the environment.": "Filtrer Annonser for usolgt mat og drikke ved slutten av dagen. Unngå å kaste bort god mat, spar penger og støtt miljøet.",
  "Brands Nearby": "Merker i Nærheten",
  "Selected Brands": "Utvalgte Merker",
  "Merchandise": "Handelsvarer",
  "Cuisines": "Kjøkken",
  "Show All": "Vis Alt",
  "Meals": "Måltider",
  "Price Level": "Prisnivå",
  "Takeaway": "Takeaway",
  "Type of Bar": "Type Bar",
  "Specialties": "Spesialiteter",
  "Profile": "Profil",
  "Opening Hour": "Åpningstid",
  "Payment Options": "Betalingsalternativer",
  "Parking": "Parkering",
  "Bookmark": "Bokmerke",
  "Filter": "Filter",
  "MAP": "KART",
  "Shopping Malls": "Kjøpesentre",
  "Search Places, Categories, Brands, etc.": "Søk etter steder, kategorier, merker, etc.",
  "Free WiFi": "Gratis WiFi",
  "Outdoor Seating": "Uteservering",
  "Wheelchair Accessible": "Tilgjengelig for Rullestol",
  "Open Now": "Åpent",
  "Open At": "Åpner Kl",
  "Search": "Søk",
  "Invite Others": "Inviter andre",
  "You can't use the Invitation Link you sent to a Friend yourself.<br/><br/>Invite Friends from the menu to earn more Reward Points.": "Du kan ikke bruke invitasjonslenken du sendte til en venn selv.<br/><br/>Inviter venner fra menyen for å tjene flere belønningspoeng.",
  "Already Signed Up": "Allerede registrert",
  "You have Signed Up earlier.<br/><br/>Sign Up Reward Points are only for new users.<br/><br/>Invite Friends from the menu to earn more Points.<br/><br/>Thank you for using Brovs.": "Du har registrert deg tidligere.<br/><br/>Registreringsbelønningspoeng er kun for nye brukere.b005e</5247b class='notranslate'><br/>Inviter venner fra menyen for å tjene flere poeng.<br/><br/> du bruker Brospan>Thank.",
  "You already Manage this Business Profile": "Du administrerer allerede denne bedriftsprofilen",
  "No further action is needed.": "Ingen ytterligere handling er nødvendig.",
  "You can't use the Business Invitation Link yourself.<br/><br/>If there has occurred an error, contact Customer Service. You can find the link in the menu.": "Du kan ikke bruke Business Invitation Link selv.<br/><br/>Hvis det har oppstått en feil, kontakt kundeservice. Du finner lenken i menyen.",
  "Business is already Managed": "Virksomheten er allerede administrert",
  "<0><0></0> has already been Accessed and the Business Profile is Manged by someone else.</0><br/><br/>Notify the <4>Account Owner</4> to request Access to this Business Profile.<br/><br/>If you think there has been a mistake or violation of use, please contact <10>Customer Service</10>.": "<0><0></0> har allerede blitt åpnet og bedriftsprofilen er administrert av noen andre.</0><br/><br/>Varsle <span class='notranslate'count>207</span> <span class='notranslate'count>207 Eieren</4> for å be om tilgang til denne bedriftsprofilen.<br/><br/>, du tror det har vært en bruksfeil, eller du tror det har vært en feil, <span> class='notranslate'><10>Kundeservice</10>.",
  "Welcome to Brovs": "Velkommen til Brovs",
  "You earned Reward Points just by Signing Up and Viewing the Business Profile.<br/><br/>Invite Friends from the menu to earn more Points or Share Business Profiles and Ads.<br/><br/>Check out Lottery and how to use the Points.": "Du tjente belønningspoeng bare ved å registrere deg og se bedriftsprofilen.<br/><br/>Inviter venner fra menyen for å tjene flere poeng eller dele bedriftsprofiler og annonser.<br/><br/>Sjekk ut lotteriet og hvordan du bruker poengene.",
  "You earned Reward Points": "Du har tjent belønningspoeng",
  "You earned Reward Points just by Viewing the Shared Business Profile.<br/><br/>Invite Friends from the menu to earn more Reward Points or Share Business Profiles and Ads.<br/><br/>Check out Lottery and how to use the Points.": "Du tjente belønningspoeng bare ved å se den delte bedriftsprofilen.<br/><br/>Inviter venner fra menyen for å tjene flere belønningspoeng eller del bedriftsprofiler.<br/><br/>Sjekk ut lotteriet og hvordan du bruker poengene.",
  "Share with Others": "Del med andre",
  "You can’t use the Share Business Profile Link yourself to earn Reward Points.<br/><br/>If there has occurred an error, contact Customer Support. You can find the link in the menu.": "Du kan ikke bruke Share Business Profile Link selv for å tjene belønningspoeng.<br/><br/>Hvis det har oppstått en feil, kontakt kundestøtte. Du finner lenken i menyen.",
  "Invitation has already been used": "Invitasjonen er allerede brukt",
  "This Invitation Link has already been used.<br/><br/>Invite Friends from the menu to earn more Reward Points or Share Business Profiles and Ads.": "Denne invitasjonslenken har allerede blitt brukt.<br/><br/>Inviter venner fra menyen for å tjene flere belønningspoeng eller dele bedriftsprofiler og annonser.",
  "You earned Reward Points just by Signing Up and Viewing the Ad.<br/><br/>Invite Friends from the menu to earn more Points or Share Business Profiles and Ads.<br/><br/>Check out Lottery and how to use the Points.": "Du tjente belønningspoeng bare ved å registrere deg og se annonsen.<br/><br/>Inviter venner fra menyen for å tjene flere poeng eller dele bedriftsprofiler og annonser.<br/><br/>Sjekk ut lotteriet og hvordan du bruker poengene.",
  "You earned Reward Points just by Viewing the Ad.<br/><br/>Invite Friends from the menu to earn more Points or Share Business Profiles and Ads.<br/><br/>Check out Lottery and how to use the Points.": "Du tjente belønningspoeng bare ved å se annonsen.<br/><br/>Inviter venner fra menyen for å tjene flere poeng eller dele bedriftsprofiler og annonser.<br/><br/>Sjekk ut lotteriet og hvordan du bruker poengene.",
  "You can’t use the Ad Invitation Link yourself.<br/><br/>If there has occurred an error, contact Customer Support. You can find the link in the menu.": "Du kan ikke bruke annonseinvitasjonskoblingen selv.<br/><br/>Hvis det har oppstått en feil, kontakt kundestøtte. Du finner lenken i menyen.",
  "You earned Reward Points just by Signing Up.<br/><br/>Invite Friends from the menu to earn more Points.<br/><br/>Check out Lottery and how to use the Points.": "Du har tjent belønningspoeng bare ved å registrere deg.<br/><br/>Inviter venner fra menyen for å tjene flere poeng.<span class='notranslate'5><a7b0e524z0 class='notranslate'><br/></span>Sjekk ut lotteriet og hvordan du bruker poengene.",
  "Type your Home Address": "Skriv inn Hjemmeadressen din",
  "Street": "Gate",
  "Number": "Nummer",
  "My address doesn't have a Street Number.": "Adressen min har ikke et Gatenummer.",
  "Postal Code": "Postnummer",
  "City": "By",
  "Change Home Address": "Endre Hjemmeadresse",
  "Search Address": "Søk Adresse",
  "Type your home address": "Skriv inn hjemmeadressen din",
  "Happy Hour": "Happy Hour",
  "Invalid": "Ugyldig",
  "Number or Postal Code is not correct.": "Nummer eller Postnummer er ikke riktig.",
  "Home": "Hjem",
  "Tap <1><0></0></1> then Scroll Down": "Trykk <1> <0> </0> </1> og bla nedover",
  "Tap <1>Add to Home Screen</1> <3><0></0></3>": "Trykk på <1> Legg til på Hjem-skjermen</1> <3> <0> </0> </3>",
  "Tap <1>Add</1>": "Trykk på <1> Legg til </1>",
  "Tap BROVS Icon on Home Screen": "Trykk på BROVS Ikonet på Startskjermen",
  "Add Brovs icon to Home Screen to install as an App.": "Legg til Brovs-ikonet på startskjermen for å installere som en app.",
  "Add BROVS icon to Home Screen for easy access.": "Legg til BROVS-ikonet på startskjermen for enkel tilgang.",
  "Norwegian": "Norsk",
  "English US": "Engelsk USA",
  "Set new password <2>here</2>.": "Angi nytt passord <2>her</2>.",
  "Create Password <2>here</2>.": "Opprett passord <2>her</2>.",
  "Sign In": "Logg Inn",
  "Password": "Passord",
  "Sign In Now": "Logg Inn Nå",
  "Forgot your password?": "Glemt Passord?",
  "To Sign In with Mobile Number you need to create a Password.": "For å logge på med mobilnummer må du opprette et passord.",
  "You have previously Signed In with Vipps.": "Du har tidligere logget på med Vipps.",
  "Continue with Mobile Number": "Fortsett med Mobilnummer",
  "How many points do you want to<1></1>convert to Lottery tickets?": "Hvor mange poeng vil du <1> </1> konvertere til lodd?",
  "Get Lottery Tickets": "Skaff Deg Lodd",
  "My Reward Points": "Mine Bonuspoeng",
  "You don't have Reward Points to get Lottery tickets.": "Du har ikke Bonuspoeng for å få lodd.",
  "See how to Earn Reward Points.": "Se hvordan du tjener Bonuspoeng.",
  "One point = One Lottery Ticket": "Ett poeng = Ett lodd",
  "Get Now": "Få Nå",
  "Cancel": "Avbryt",
  "Congratulations!<1></1>You are now participating in the Lottery.": "Gratulerer! <1> </1> Du deltar nå i Lotteriet.",
  "There is currently no active Lottery": "Det er for øyeblikket ikke noe aktivt lotteri",
  "The Brovs Lottery is where you can spend your earned Brovs Reward Points.<br/><br/>You can earn Reward Points by Inviting Friends to join Brovs, Invite Business Owners and Employees to manage their business profile on the Brovs Web Portal or you can Share Business Profiles and Ads with people you might think have an interest.<br/><br/>You can learn more about how to Earn Reward Points by visiting Rewards in the menu.<br/><br/>Check back for upcoming Lotteries. New chances to win is coming soon.<br/><br/>Stay tuned!": "Brovs-lotteriet er der du kan bruke dine opptjente Brovs-belønningspoeng.<br/><br/>Du kan tjene belønningspoeng ved å invitere venner til å bli med i bedriftsprofilen deres og administrere bedriftsprofilen deres og administrere bedriftsprofilen deres. Brovs nettportal eller du kan dele bedriftsprofiler og annonser med folk du kanskje tror har en interesse.<br/><br/>Du kan lære mer om hvordan du tjener belønningspoeng i menyen ved å gå til belønningspoeng.<br/><br/>Sjekk tilbake for kommende lotterier. Nye sjanser til å vinne kommer snart.<br/><br/>Følg med!",
  "Lottery": "Lotteri",
  "My Tickets In This Lottery": "Mine Lodd I Dette Lotteriet",
  "Get More Lottery Tickets": "Få flere lodd",
  "Previous Lottery": "Forrige Lotteri",
  "Ended on": "Avsluttet på",
  "Congratulations, you are the winner of this Lottery!": "Gratulerer, du er vinneren av dette Lotteriet!",
  "Drawn Winner": "Uttrukket Vinner",
  "New Version Available": "Ny Versjon Tilgjengelig",
  "Update Now": "Oppdater Nå",
  "Options": "Alternativer",
  "Unbookmark": "Fjern Bokmerke",
  "Report Problem": "Rapporter problem",
  "Set New Password": "Lag Nytt Passord",
  "Send Short Code": "Send Kortkode",
  "Forgot your password?. No problem. You can create a new password below.": "Glemt passordet?. Ikke noe problem. Du kan opprette et nytt passord nedenfor.",
  "Check SMS for sent Short Code.": "Sjekk SMS for sendt Kortkode.",
  "Type New Password": "Skriv inn Nytt Passord",
  "New Password": "Nytt Passord",
  "Access {{placeName}}": "Få Tilgang Til {{placeName}}",
  "Expires in {{claimBusinessLinkExpirationTime}} seconds.": "Utløper om {{claimBusinessLinkExpirationTime}} sekunder.",
  "Are you the Owner or an Employee at<br/>{{placeName}}?": "Er du eieren eller en ansatt på<br/>{{placeName}}?",
  "Do you know someone who works at<br/>{{placeName}}?": "Kjenner du noen som arbeider hos 1{{placeName}}?",
  "Hi :-)\nI invite you to Manage “{{name}}” in Brovs for FREE. Brovs is the new website to browse for Ads, Products and Services at Local Businesses. Fashion - Food & Drink - Rewards - and more.": "Hei :-)\nJeg inviterer deg til å administrere «{{name}}» i Brovs GRATIS. Brovs er det nye nettstedet for å søke etter annonser, produkter og tjenester hos lokale bedrifter. Mote - Mat og drikke - Belønninger - og mer.",
  "Access Now": "Få Tilgang Nå",
  "Get access and Manage the Business Profile for FREE. Update business name, opening hours etc. Advertise to a large user audience to get new customers.": "Få tilgang til og administrer bedriftsprofilen GRATIS. Oppdater bedriftsnavn, åpningstider etc. Annonser til et stort brukerpublikum for å få nye kunder.",
  "Manage Now": "Administrer Nå",
  "Invite the Owner or an Employee to Manage the Business Profile for FREE. When they get access you will receive Brovs Reward Points that you can use in the Brovs Lottery.": "Inviter eieren eller en ansatt til å administrere bedriftsprofilen GRATIS. Når de får tilgang vil du motta Brovs Reward-poeng som du kan bruke i Brovs-lotteriet.",
  "Invite by SMS": "Inviter med SMS",
  "Send Referral": "Send vervelenke",
  "SMS": "SMS",
  "Other": "Annet",
  "Open": "Åpent",
  "Closes": "Stenger",
  "Closed": "Lukket",
  "Opens": "Åpner",
  "Report a problem related to this business?": "Rapportere et problem relatert til denne virksomheten?",
  "We appreciate any kind of feedback that could make our service better. Contact Support to report a problem. Thank you in advance.": "Vi setter pris på alle slags tilbakemeldinger som kan gjøre tjenesten vår bedre. Kontakt kundestøtte for å rapportere et problem. Takk på forhånd.",
  "This profile is managed by<br/>.": "Denne profilen administreres av1.",
  "Verified Profile": "Verifisert Profil",
  "Overview": "Oversikt",
  "Menu": "Meny",
  "Contact": "Kontakt",
  "Share": "Del",
  "Interested in Ads?": "Interessert i Annonser?",
  "You have expressed interest in Ads from this business.": "Du har vist interesse for Annonser fra denne virksomheten.",
  "Let Us Know": "Gi Oss Beskjed",
  "Brands not added.": "Merker ikke lagt til. ",
  "Categories and Brands": "Kategorier og Merker",
  "Shopping Mall": "Kjøpesenter",
  "Website": "Nettsted",
  "Webshop": "Nettbutikk",
  "Not added": "Ikke lagt til",
  "WiFi": "WiFi",
  "Free": "Gratis",
  "Yes": "Ja",
  "No": "Nei",
  "Suitability": "Egnet for",
  "Accessibility": "Tilgjengelighet",
  "Managed by": "Administrert av",
  "Earn Reward Points by inviting the Owner or an Employee to manage the Business Profile.": "Tjen Bonuspoeng ved å invitere Eieren eller en Ansatt til å administrere Bedriftsprofilen.",
  "Manage Business Profile": "Administrer bedriftsprofil",
  "Invite or Manage": "Inviter eller Administrer",
  "Menu has not been added.": "Menyen er ikke lagt til.",
  "Opening Hours": "Åpningstider",
  "Monday": "Mandag",
  "Tuesday": "Tirsdag",
  "Wednesday": "Onsdag",
  "Thursday": "Torsdag",
  "Friday": "Fredag",
  "Saturday": "Lørdag",
  "Sunday": "Søndag",
  "Categories": "Bransje",
  "Floor": "Etasje",
  "Accept <1>User Terms of Service</1> and <3>Privacy Policy</3>.": "Godta <1>Brukervilkår - Person</1> og <3>Personvernregler</3>.",
  "Privacy Policy": "Personvernerklæring",
  "Terms of Service": "Brukervilkår - Person",
  "Date of Birth": "Fødselsdato",
  "Day": "Dag",
  "Month": "Måned",
  "Year": "År",
  "Photo": "Bilde",
  "Invite Friends to join Brovs": "Inviter venner til å bli med i Brovs",
  "When Invited Friends Sign's Up you will receive Reward Points that you can use in the Brovs Lottery.<br/><br/>The more Friends you Invite the more Reward Points you earn.<br/><br/>Invite as many Friends as you like.": "Når Invited Friends registrerer seg vil du motta belønningspoeng som du kan bruke i Brovs-lotteriet.<br/><br/>Jo flere venner får du belønningspoeng.< Inviterer du class='notranslate'><br/></span><br/>Inviter så mange venner du vil.",
  "Hi :-)\nI recommend checking out Brovs, the new website to browse for Ads, Products and Services at Local Businesses. Fashion - Food & Drink - Rewards - and more.": "Hei :-)\nJeg anbefaler å sjekke ut Brovs, det nye nettstedet for å søke etter annonser, produkter og tjenester hos lokale bedrifter. Mote - Mat og drikke - Belønninger - og mer.",
  "BROVS": "BROVS",
  "Invite Business Owners or Employees to Manage their Business Profile for free. You will earn Reward Points when they access their Business Profile. You can Invite directly from the Business Profile.": "Inviter bedriftseiere eller ansatte til å administrere bedriftsprofilen sin gratis. Du vil tjene belønningspoeng når de får tilgang til bedriftsprofilen sin. Du kan invitere direkte fra bedriftsprofilen.",
  "Invite Business Owners or Employees": "Inviter bedriftseiere eller ansatte",
  "Use Invite or Manage Button for Business Profile": "Bruk Inviter eller Administrer-knappen for bedriftsprofil",
  "Text Message / SMS": "Tekstmelding / SMS",
  "(Only Norwegian Numbers)": "(Kun Norske Mobilnumre)",
  "Points": "Poeng",
  "You earn Rewards Points simply by Inviting Friends to use Brovs, Inviting Owners or Employees of a Business to Manage their Business Profile for free or Share Business Profiles and Ads with people you might think have an interest.": "Du tjener belønningspoeng ganske enkelt ved å invitere venner til å bruke Brovs, invitere eiere eller ansatte i en bedrift til å administrere bedriftsprofilen deres gratis eller dele bedriftsprofiler og annonser med folk du kanskje tror har en interesse.",
  "Earn Reward Points": "Tjen Bonus Poeng",
  "Invite Friends": "Inviter Venner",
  "Share Businesses": "Del virksomheter",
  "Share Ads": "Del annonser",
  "Sign Up Bonus": "Registreringsbonus",
  "Invite Friends to use Brovs and you earn Reward Points when they Sign Up as a new Brovs User. You can Invite as many Friends as you like.": "Inviter venner til å bruke Brovs, og du tjener belønningspoeng når de registrerer seg som en ny Brovs-bruker. Du kan invitere så mange venner du vil.",
  "Below are your total Brovs Reward Points and how you earned them. The Points can be used to participate in the Brovs Lottery.": "Nedenfor er dine totale Brovs Reward-poeng og hvordan du tjente dem. Poengene kan brukes til å delta i Brovs-lotteriet.",
  "My Contributions": "Mine bidrag",
  "Invited Friends": "Inviterte venner",
  "Invited Businesses": "Inviterte bedrifter",
  "Shared Businesses": "Delte virksomheter",
  "Shared Ads": "Delte annonser",
  "Expiration of Points by 31. December": "Poeng om utløper 31. desember",
  "My Rewards": "Mine Bonuspoeng",
  "Share Ads with people. You will earn Reward Points when Ads you shared are viewed. You can share directly from the Ad.": "Del annonser med folk. Du vil tjene belønningspoeng når annonsene du har delt blir sett. Du kan dele direkte fra annonsen.",
  "Use Share Icon for Ad ": "Bruk deleikon for annonse ",
  "Share Business Profiles with people. You will earn Reward Points when Business Profiles you shared are viewed. You can Share directly from the Business Profile.": "Del bedriftsprofiler med folk. Du vil tjene belønningspoeng når bedriftsprofiler du har delt blir sett. Du kan dele direkte fra bedriftsprofilen.",
  "Share Business Profiles": "Del bedriftsprofiler",
  "Use Share Icon for Business Profile": "Bruk delingsikon for bedriftsprofil",
  "You can use the earned Brovs Reward Points to get Lottery Tickets in the Brovs Lottery.<br/><br/>One Reward Point = One Lottery Ticket<br/><br/>Go To Lottery for more information.<br/><br/>Best of luck": "Du kan bruke de opptjente Brovs Reward-poengene til å få lodd i Brovs-lotteriet.<br/><br/>Ett belønningspoeng< = ett lotterilodd class='notranslate'><br/></span><br/>Gå til lotteriet for mer informasjon.<br/><br/>Lykke til",
  "Use Reward Points": "Bruk Bonuspoeng",
  "Go To Lottery": "Gå til lotteri",
  "Account": "Konto",
  "Add your E-mail for communication and promotions.": "Legg til din e-post for kommunikasjon og kampanjer.",
  "Verified": "Verifisert",
  "Not Verified.": "Ikke Verifisert.",
  "Send Short Code Verification to E-mail.": "Send kortkode til e-post.",
  "Short Code Verification sent to above E-mail.": "Kortkode for verifisering er sendt til din e-post.",
  "Have you not received an E-mail with the Short Code Verification?": "Har du ikke mottatt en e-post med kortkode?",
  "Language": "Språk",
  "Change Password": "Endre Passord",
  "Add Password": "Legg til Passord",
  "Are you sure you want to change Password?": "Er du sikker på at du vil endre Passord?",
  "Personal Information *": "Personlig Informasjon *",
  "Gender": "Kjønn",
  "Invalid Mobile Number.": "Ugyldig Mobilnummer.",
  "Change if not correct.": "Endre hvis ikke korrekt.",
  "Verified.": "Verifisert.",
  "Check your SMS for Short Code": "Sjekk SMS-ene din for kortkode.",
  "Confirm Mobile Number": "Bekreft Mobilnummer",
  "Crop width or height is not set": "Bredde eller høyde på utsnitt er ikke satt",
  "Could not get canvas context": "Kunne ikke få tak i lerretskontekst",
  "Canvas is empty": "Lerret er tomt",
  "Image not found.": "Bilde ikke funnet.",
  "Crop dimensions not set.": "Beskjæringsdimensjoner ikke satt.",
  "Error decoding image.": "Feil ved dekoding av bilde.",
  "Profile Photo": "Profilbilde",
  "Upload New": "Last opp Nytt",
  "Upload": "Last opp",
  "Delete": "Slett",
  "Crop Photo": "Beskjær Bilde",
  "Sure you want to delete?": "Er du sikker på at du vil slette?",
  "Settings": "Innstillinger",
  "Sign Out": "Logg Ut",
  "Delete Account": "Slett Konto",
  "Are you sure you want to delete Your Account?": "Er du sikker på at du vil slette Kontoen din?",
  "Delete My Account": "Slett Kontoen Min",
  "Share Ad": "Del annonse",
  "You will receive Brovs Reward Points when the Ad is viewed by people you shared it with.<br/><br/>You can use Reward Points in the Brovs Lottery.<br/><br/>Share with as many people as you like.": "Du vil motta Brovs-belønningspoeng når annonsen blir sett av folk du har delt den med.<br/><br/>Du kan bruke belønningspoeng i Brovs-lotteriet.<br/><br/>Del med så mange personer du vil.",
  "Hi :-)\nYou might have an interest in this Ad; “{{adTitle}}” from “{{placeName}}”. View in Brovs.com, the new website to browse for Ads, Products and Services at Local Businesses. Fashion - Food & Drink - Rewards - and more.": "Hei :-)\nDu kan være interessert i denne annonsen; «{{adTitle}}» fra «{{placeName}}». Se på Brovs.com, det nye nettstedet for å søke etter annonser, produkter og tjenester hos lokale bedrifter. Mote - Mat og drikke - Belønninger - og mer.",
  "Share With SMS": "Del med SMS",
  "Share Business Profile": "Del bedriftsprofil",
  "You will receive Brovs Reward Points when Shared Business Profile is viewed by people you share it with.<br/><br/>You can use Reward Points in the Brovs Lottery.<br/><br/>Share with as many people as you like.": "Du vil motta Brovs-belønningspoeng når delt bedriftsprofil vises av folk du deler den med.<br/><br/>Du kan bruke belønningspoeng i Brovs-lotteriet.<br/><br/>Del med så mange personer du vil.",
  "Hi :-)\nYou might have an interest in “{{name}}”. View in Brovs, the new website to browse for Ads, Products and Services at Local Businesses. Fashion - Food & Drink - Rewards - and more.": "Hei :-)\nDu kan være interessert i «{{navn}}». Se i Brovs, det nye nettstedet for å søke etter annonser, produkter og tjenester hos lokale bedrifter. Mote - Mat og drikke - Belønninger - og mer.",
  "Manage Business": "Administrer Virksomhet",
  "Rewards": "Bonus",
  "Business": "Virksomhet",
  "Easy and FREE Sign In": "Enkel og GRATIS Innlogging",
  "Brovs is a website where you can browse Ads, Products and Services at Local Businesses.<br/><br/>As Owner or Employee you get access to the Brovs Business Platform so you can Manage the Business Profile for FREE.": "Brovs er et nettsted hvor du kan bla gjennom annonser, produkter og tjenester hos lokale bedrifter.<br/><br/>Som eier eller ansatt får du tilgang til bedriftsprofilen GRATIS.",
  "thinks you have an interest in<br/>.": "tror du har en interesse i<br/>.",
  "Brovs is a website where you can browse Ads, Products and Services at Local Businesses.<br/><br/>You earn Reward Points by Signing Up or Viewing the Business Profile.": "Brovs er et nettsted hvor du kan bla gjennom annonser, produkter og tjenester hos lokale bedrifter.<br/><br/>Du tjener belønningspoeng ved å registrere deg eller se bedriftsprofilen.",
  "Browse ads, products and<br/>services at local businesses": "Bla gjennom annonser, produkter og<br/>tjenester hos lokale virksomheter",
  "invites you to manage {{businessName}}.": "inviterer deg til å administrere {{businessName}}.",
  "thinks you have an interest in this Ad": "tror du har interesse for denne annonsen",
  "Fashion": "Fashion",
  "Food & Drink": "Mat & Drikke",
  "thinks you have an interest in using Brovs": "tror du har interesse av å bruke Brovs",
  "Sign In now to receive Reward Points and browse Ads, Products and Services at Local Businesses.": "Logg på nå for å motta belønningspoeng og bla gjennom annonser, produkter og tjenester hos lokale bedrifter."
}
