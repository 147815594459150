import { ConsumerApi } from "../ConsumerApi.dto";
import { DateTime } from "luxon";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { SeparatorHeader } from "../separator-header";

type MyRewardPointsProps = {
  rewardsStatus: ConsumerApi.GetRewardsStatusResponse;
};

const MyRewardPoints: React.FC<MyRewardPointsProps> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <SeparatorHeader title={t("My Reward Points")} />
      <div className="flex items-center text-title mt-[15px]">
        <Trans>
          Below are your total Brovs Reward Points and how you earned them. The
          Points can be used to participate in the Brovs Lottery.
        </Trans>
      </div>
      <div className="mt-[30px]">
        <div className="text-body flex justify-center">
          {t("My Reward Points")}
        </div>
        <div className="text-h1 flex justify-center">
          {props.rewardsStatus.pointsInTotal}
        </div>
      </div>
      <div className="flex justify-center text-title-bold mt-[24px]">
        {t("My Contributions")}
      </div>
      <div className="flex mt-[15px]">
        <div className="w-1/2 flex flex-col items-center">
          <div className="text-body">{t("Invited Friends")}</div>
          <div className="text-h6 mt-[5px]">
            {props.rewardsStatus.referFriend.count}
          </div>
        </div>
        <div className="w-1/2 flex flex-col items-center">
          <div className="text-body">{t("Invited Businesses")}</div>
          <div className="text-h6 mt-[5px]">
            {props.rewardsStatus.referBusiness.count}
          </div>
        </div>
      </div>
      <div className="flex mt-[20px]">
        <div className="w-1/2 flex flex-col items-center">
          <div className="text-body">{t("Shared Businesses")}</div>
          <div className="text-h6 mt-[5px]">
            {props.rewardsStatus.sharePlace.count}
          </div>
        </div>
        <div className="w-1/2 flex flex-col items-center">
          <div className="text-body">{t("Shared Ads")}</div>
          <div className="text-h6 mt-[5px]">
            {props.rewardsStatus.shareAdMessage.count}
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center mt-[20px]">
        <div className="text-body">
          {t("Expiration of Points by 31. December")} {DateTime.now().year}
        </div>
        <div className="text-h6 mt-[5px]">
          {props.rewardsStatus.pointsExpiringAtEndOfThisYear}
        </div>
      </div>
    </>
  );
};

export default MyRewardPoints;
