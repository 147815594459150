import { ConsumerApi } from "../ConsumerApi.dto";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { ShareIcon } from "../icons";
import DisplayPoints from "./DisplayPoints";

type SharedAdMessagesProps = {
  rewardsStatus: ConsumerApi.GetRewardsStatusResponse;
};

const SharedAdMessages: React.FC<SharedAdMessagesProps> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <DisplayPoints
        title={t("Share Ads")}
        description={
          <Trans>
            Share Ads with people. You will earn Reward Points when Ads you
            shared are viewed. You can share directly from the Ad.
          </Trans>
        }
        points={props.rewardsStatus.shareAdMessage.points}
        helpText={t("Use Share Icon for Ad ")}
        helpExtra={<ShareIcon />}
      />
    </>
  );
};

export default SharedAdMessages;
