{
  "Starts": "Starts",
  "Ends": "Ends",
  "View Ad": "View Ad",
  "More Information": "More Information",
  "View": "View",
  "Bookmark your Search": "Bookmark your Search",
  "Bookmark and name your search result for easy access later. You will find the bookmarked searches in the main menu.": "Bookmark and name your search result for easy access later. You will find the bookmarked searches in the main menu.",
  "Name your search result": "Name your search result",
  "Save Bookmark": "Save Bookmark",
  "Bookmarks": "Bookmarks",
  "Save": "Save",
  "Edit": "Edit",
  "Search name": "Search name",
  "Keep Bookmark": "Keep Bookmark",
  "Remove Bookmark": "Remove Bookmark",
  "Current Location": "Current Location",
  "Change Location": "Change Location",
  "Search Location": "Search Location",
  "Warning Press Allow!": "Warning Press Allow!",
  "To browse Ads, Products and Services at your Current Location you need to Tap “Allow” in the next screen when asked for Current Location.": "To browse Ads, Products and Services at your Current Location you need to Tap “Allow” in the next screen when asked for Current Location.",
  "To browse Ads, Products and Services at your Current Location you need to Tap ”Allow” in the next screen when asked for Device Location.": "To browse Ads, Products and Services at your Current Location you need to Tap ”Allow” in the next screen when asked for Device Location.",
  "OK": "OK",
  "Your Current Location is not available": "Your Current Location is not available",
  "To browse Ads, Products and Services at your Current Location you need to enable Location Services.": "To browse Ads, Products and Services at your Current Location you need to enable Location Services.",
  "Enable Location": "Enable Location",
  "Ok": "Ok",
  "Open Settings on Your Phone": "Open Settings on Your Phone",
  "Tap Privacy & Security": "Tap Privacy & Security",
  "Privacy & Security": "Privacy & Security",
  "Tap Location Services": "Tap Location Services",
  "Location Services": "Location Services",
  "Off": "Off",
  "Activate: Location Services": "Activate: Location Services",
  "Scroll Down and Tap on Safari Websites": "Scroll Down and Tap on Safari Websites",
  "Safari Websites": "Safari Websites",
  "Never": "Never",
  "Activate: While Using the App": "Activate: While Using the App",
  "While Using the App": "While Using the App",
  "Activate: Precise Location": "Activate: Precise Location",
  "Precise Location": "Precise Location",
  "Open Phone Settings": "Open Phone Settings",
  "Scroll Down and Tap Safari": "Scroll Down and Tap Safari",
  "Safari": "Safari",
  "Scroll Down and Tap Location": "Scroll Down and Tap Location",
  "Location": "Location",
  "When displaying:": "When displaying:",
  "LOCATION ACCESS ON ALL WEBSITES": "LOCATION ACCESS ON ALL WEBSITES",
  "Then activate: Allow": "Then activate: Allow",
  "Allow": "Allow",
  "OR": "OR",
  "If it has the entry below:": "If it has the entry below:",
  "www.brovs.com": "www.brovs.com",
  "Activate: Allow": "Activate: Allow",
  "Tap Location": "Tap Location",
  "Enable: Location": "Enable: Location",
  "Tap App permissions": "Tap App permissions",
  "App permissions": "App permissions",
  "Scroll Down and Tap Chrome": "Scroll Down and Tap Chrome",
  "Chrome": "Chrome",
  "Allow all the time": "Allow all the time",
  "Allow while using the app": "Allow while using the app",
  "Open Chrome on Your Phone": "Open Chrome on Your Phone",
  "Type brovs.com in the Chrome (URL)": "Type brovs.com in the Chrome (URL)",
  "brovs.com": "brovs.com",
  "Tap Site Settings Icon": "Tap Site Settings Icon",
  "Tap Permissions": "Tap Permissions",
  "Permissions": "Permissions",
  "Allowed": "Allowed",
  "Enable Location for Safari": "Enable Location for Safari",
  "Enable Location for Chrome": "Enable Location for Chrome",
  "Enable Location for Website": "Enable Location for Website",
  "Well Done!": "Well Done!",
  "Please make sure to enable Location Services for Safari and Website so you can browse at your Current Location. Follow the guide below.": "Please make sure to enable Location Services for Safari and Website so you can browse at your Current Location. Follow the guide below.",
  "Please make sure to enable Location Services for Chrome and Website so you can browse at your Current Location. Follow the guide below.": "Please make sure to enable Location Services for Chrome and Website so you can browse at your Current Location. Follow the guide below.",
  "That was a chore, but now you can enjoy BROVS and browse Ads, Products and Services at your Current Location.": "That was a chore, but now you can enjoy BROVS and browse Ads, Products and Services at your Current Location.",
  "Back": "Back",
  "Next": "Next",
  "Done": "Done",
  "Invalid Link. Contact Customer Service.": "Invalid Link. Contact Customer Service.",
  "Verified E-mail": "Verified E-mail",
  "Your E-mail {{email}} has been verified.": "Your E-mail {{email}} has been verified.",
  "Are you sure you want to Unbookmark": "Are you sure you want to Unbookmark",
  "Confirm": "Confirm",
  "Accept to create a profile.": "Accept to create a profile.",
  "Create Profile": "Create Profile",
  "To BROVS ads, products and services you need to create a Profile.": "To BROVS ads, products and services you need to create a Profile.",
  "Gender *": "Gender *",
  "Female": "Female",
  "Male": "Male",
  "Create Profile Now": "Create Profile Now",
  "Required": "Required",
  "Invalid E-mail.": "Invalid E-mail.",
  "Minimum password length is 8 characters.": "Minimum password length is 8 characters.",
  "Repeat Password doesn't match.": "Repeat Password doesn't match.",
  "Accept to create a Profile.": "Accept to create a Profile.",
  "Address is required.": "Address is required.",
  "First Name": "First Name",
  "First Name *": "First Name *",
  "Last Name": "Last Name",
  "Last Name *": "Last Name *",
  "Mobile Number *": "Mobile Number *",
  "Continue": "Continue",
  "Type your E-mail": "Type your E-mail",
  "E-mail": "E-mail",
  "Don't have an E-mail?": "Don't have an E-mail?",
  "Type Password": "Type Password",
  "Create Password *": "Create Password *",
  "Repeat Password": "Repeat Password",
  "Home Location *": "Home Location *",
  "Home address is used to display nearby Businesses and Ads.": "Home address is used to display nearby Businesses and Ads.",
  "Are you sure you want to cancel Sign Up?": "Are you sure you want to cancel Sign Up?",
  "Verify Mobile Number": "Verify Mobile Number",
  "You will receive a SMS with a Short Code to verify your Mobile Number.": "You will receive a SMS with a Short Code to verify your Mobile Number.",
  "Mobile Number": "Mobile Number",
  "Is your Mobile Number below correct?": "Is your Mobile Number below correct?",
  "Edit if not correct.": "Edit if not correct.",
  "Check your SMS for Short Code.": "Check your SMS for Short Code.",
  "Short Code": "Short Code",
  "Type Short Code": "Type Short Code",
  "Didn't receive a Short Code?": "Didn't receive a Short Code?",
  "Resend Code": "Resend Code",
  "Save Food & Money": "Save Food & Money",
  "Ads": "Ads",
  "Places": "Places",
  "Searches": "Searches",
  "End Of Day Unsold Food And Beverages": "End Of Day Unsold Food And Beverages",
  "All Categories": "All Categories",
  "Choose <1>Brands</1> from the list below.": "Choose <1>Brands</1> from the list below.",
  "Filters": "Filters",
  "Apply": "Apply",
  "Search Brands": "Search Brands",
  "Brands": "Brands",
  "Filter by <1>Brands</1> only to view nearby Brands. Filter by <3>Merchandise</3> only to view nearby Merchandise. You can combine Brands and Merchandise for a more specific search.<br/>Tip: Choose one combination for best result.": "Filter by <1>Brands</1> only to view nearby Brands. Filter by <3>Merchandise</3> only to view nearby Merchandise. You can combine Brands and Merchandise for a more specific search.<br/>Tip: Choose one combination for best result.",
  "Filter Ads for end of day unsold food and beverages. Avoid wasting perfectly good food, save money and support the environment.": "Filter Ads for end of day unsold food and beverages. Avoid wasting perfectly good food, save money and support the environment.",
  "Brands Nearby": "Brands Nearby",
  "Selected Brands": "Selected Brands",
  "Merchandise": "Merchandise",
  "Cuisines": "Cuisines",
  "Show All": "Show All",
  "Meals": "Meals",
  "Price Level": "Price Level",
  "Takeaway": "Takeaway",
  "Type of Bar": "Type of Bar",
  "Specialties": "Specialties",
  "Profile": "Profile",
  "Opening Hour": "Opening Hour",
  "Payment Options": "Payment Options",
  "Parking": "Parking",
  "Bookmark": "Bookmark",
  "Filter": "Filter",
  "MAP": "MAP",
  "Shopping Malls": "Shopping Malls",
  "Search Places, Categories, Brands, etc.": "Search Places, Categories, Brands, etc.",
  "Free WiFi": "Free WiFi",
  "Outdoor Seating": "Outdoor Seating",
  "Wheelchair Accessible": "Wheelchair Accessible",
  "Open Now": "Open Now",
  "Open At": "Open At",
  "Search": "Search",
  "Invite Others": "Invite Others",
  "You can't use the Invitation Link you sent to a Friend yourself.<br/><br/>Invite Friends from the menu to earn more Reward Points.": "You can't use the Invitation Link you sent to a Friend yourself.<br/><br/>Invite Friends from the menu to earn more Reward Points.",
  "Already Signed Up": "Already Signed Up",
  "You have Signed Up earlier.<br/><br/>Sign Up Reward Points are only for new users.<br/><br/>Invite Friends from the menu to earn more Points.<br/><br/>Thank you for using Brovs.": "You have Signed Up earlier.<br/><br/>Sign Up Reward Points are only for new users.<br/><br/>Invite Friends from the menu to earn more Points.<br/><br/>Thank you for using Brovs.",
  "You already Manage this Business Profile": "You already Manage this Business Profile",
  "No further action is needed.": "No further action is needed.",
  "You can't use the Business Invitation Link yourself.<br/><br/>If there has occurred an error, contact Customer Service. You can find the link in the menu.": "You can't use the Business Invitation Link yourself.<br/><br/>If there has occurred an error, contact Customer Service. You can find the link in the menu.",
  "Business is already Managed": "Business is already Managed",
  "<0><0></0> has already been Accessed and the Business Profile is Manged by someone else.</0><br/><br/>Notify the <4>Account Owner</4> to request Access to this Business Profile.<br/><br/>If you think there has been a mistake or violation of use, please contact <10>Customer Service</10>.": "<0><0></0> has already been Accessed and the Business Profile is Manged by someone else.</0><br/><br/>Notify the <4>Account Owner</4> to request Access to this Business Profile.<br/><br/>If you think there has been a mistake or violation of use, please contact <10>Customer Service</10>.",
  "Welcome to Brovs": "Welcome to Brovs",
  "You earned Reward Points just by Signing Up and Viewing the Business Profile.<br/><br/>Invite Friends from the menu to earn more Points or Share Business Profiles and Ads.<br/><br/>Check out Lottery and how to use the Points.": "You earned Reward Points just by Signing Up and Viewing the Business Profile.<br/><br/>Invite Friends from the menu to earn more Points or Share Business Profiles and Ads.<br/><br/>Check out Lottery and how to use the Points.",
  "You earned Reward Points": "You earned Reward Points",
  "You earned Reward Points just by Viewing the Shared Business Profile.<br/><br/>Invite Friends from the menu to earn more Reward Points or Share Business Profiles and Ads.<br/><br/>Check out Lottery and how to use the Points.": "You earned Reward Points just by Viewing the Shared Business Profile.<br/><br/>Invite Friends from the menu to earn more Reward Points or Share Business Profiles and Ads.<br/><br/>Check out Lottery and how to use the Points.",
  "Share with Others": "Share with Others",
  "You can’t use the Share Business Profile Link yourself to earn Reward Points.<br/><br/>If there has occurred an error, contact Customer Support. You can find the link in the menu.": "You can’t use the Share Business Profile Link yourself to earn Reward Points.<br/><br/>If there has occurred an error, contact Customer Support. You can find the link in the menu.",
  "Invitation has already been used": "Invitation has already been used",
  "This Invitation Link has already been used.<br/><br/>Invite Friends from the menu to earn more Reward Points or Share Business Profiles and Ads.": "This Invitation Link has already been used.<br/><br/>Invite Friends from the menu to earn more Reward Points or Share Business Profiles and Ads.",
  "You earned Reward Points just by Signing Up and Viewing the Ad.<br/><br/>Invite Friends from the menu to earn more Points or Share Business Profiles and Ads.<br/><br/>Check out Lottery and how to use the Points.": "You earned Reward Points just by Signing Up and Viewing the Ad.<br/><br/>Invite Friends from the menu to earn more Points or Share Business Profiles and Ads.<br/><br/>Check out Lottery and how to use the Points.",
  "You earned Reward Points just by Viewing the Ad.<br/><br/>Invite Friends from the menu to earn more Points or Share Business Profiles and Ads.<br/><br/>Check out Lottery and how to use the Points.": "You earned Reward Points just by Viewing the Ad.<br/><br/>Invite Friends from the menu to earn more Points or Share Business Profiles and Ads.<br/><br/>Check out Lottery and how to use the Points.",
  "You can’t use the Ad Invitation Link yourself.<br/><br/>If there has occurred an error, contact Customer Support. You can find the link in the menu.": "You can’t use the Ad Invitation Link yourself.<br/><br/>If there has occurred an error, contact Customer Support. You can find the link in the menu.",
  "You earned Reward Points just by Signing Up.<br/><br/>Invite Friends from the menu to earn more Points.<br/><br/>Check out Lottery and how to use the Points.": "You earned Reward Points just by Signing Up.<br/><br/>Invite Friends from the menu to earn more Points.<br/><br/>Check out Lottery and how to use the Points.",
  "Type your Home Address": "Type your Home Address",
  "Street": "Street",
  "Number": "Number",
  "My address doesn't have a Street Number.": "My address doesn't have a Street Number.",
  "Postal Code": "Postal Code",
  "City": "City",
  "Change Home Address": "Change Home Address",
  "Search Address": "Search Address",
  "Type your home address": "Type your home address",
  "Happy Hour": "Happy Hour",
  "Invalid": "Invalid",
  "Number or Postal Code is not correct.": "Number or Postal Code is not correct.",
  "Home": "Home",
  "Tap <1><0></0></1> then Scroll Down": "Tap <1><0></0></1> then Scroll Down",
  "Tap <1>Add to Home Screen</1> <3><0></0></3>": "Tap <1>Add to Home Screen</1> <3><0></0></3>",
  "Tap <1>Add</1>": "Tap <1>Add</1>",
  "Tap BROVS Icon on Home Screen": "Tap BROVS Icon on Home Screen",
  "Add Brovs icon to Home Screen to install as an App.": "Add Brovs icon to Home Screen to install as an App.",
  "Add BROVS icon to Home Screen for easy access.": "Add BROVS icon to Home Screen for easy access.",
  "Norwegian": "Norwegian",
  "English US": "English US",
  "Set new password <2>here</2>.": "Set new password <2>here</2>.",
  "Create Password <2>here</2>.": "Create Password <2>here</2>.",
  "Sign In": "Sign In",
  "Password": "Password",
  "Sign In Now": "Sign In Now",
  "Forgot your password?": "Forgot your password?",
  "To Sign In with Mobile Number you need to create a Password.": "To Sign In with Mobile Number you need to create a Password.",
  "You have previously Signed In with Vipps.": "You have previously Signed In with Vipps.",
  "Continue with Mobile Number": "Continue with Mobile Number",
  "How many points do you want to<1></1>convert to Lottery tickets?": "How many points do you want to<1></1>convert to Lottery tickets?",
  "Get Lottery Tickets": "Get Lottery Tickets",
  "My Reward Points": "My Reward Points",
  "You don't have Reward Points to get Lottery tickets.": "You don't have Reward Points to get Lottery tickets.",
  "See how to Earn Reward Points.": "See how to Earn Reward Points.",
  "One point = One Lottery Ticket": "One point = One Lottery Ticket",
  "Get Now": "Get Now",
  "Cancel": "Cancel",
  "Congratulations!<1></1>You are now participating in the Lottery.": "Congratulations!<1></1>You are now participating in the Lottery.",
  "There is currently no active Lottery": "There is currently no active Lottery",
  "The Brovs Lottery is where you can spend your earned Brovs Reward Points.<br/><br/>You can earn Reward Points by Inviting Friends to join Brovs, Invite Business Owners and Employees to manage their business profile on the Brovs Web Portal or you can Share Business Profiles and Ads with people you might think have an interest.<br/><br/>You can learn more about how to Earn Reward Points by visiting Rewards in the menu.<br/><br/>Check back for upcoming Lotteries. New chances to win is coming soon.<br/><br/>Stay tuned!": "The Brovs Lottery is where you can spend your earned Brovs Reward Points.<br/><br/>You can earn Reward Points by Inviting Friends to join Brovs, Invite Business Owners and Employees to manage their business profile on the Brovs Web Portal or you can Share Business Profiles and Ads with people you might think have an interest.<br/><br/>You can learn more about how to Earn Reward Points by visiting Rewards in the menu.<br/><br/>Check back for upcoming Lotteries. New chances to win is coming soon.<br/><br/>Stay tuned!",
  "Lottery": "Lottery",
  "My Tickets In This Lottery": "My Tickets In This Lottery",
  "Get More Lottery Tickets": "Get More Lottery Tickets",
  "Previous Lottery": "Previous Lottery",
  "Ended on": "Ended on",
  "Congratulations, you are the winner of this Lottery!": "Congratulations, you are the winner of this Lottery!",
  "Drawn Winner": "Drawn Winner",
  "New Version Available": "New Version Available",
  "Update Now": "Update Now",
  "Options": "Options",
  "Unbookmark": "Unbookmark",
  "Report Problem": "Report Problem",
  "Set New Password": "Set New Password",
  "Send Short Code": "Send Short Code",
  "Forgot your password?. No problem. You can create a new password below.": "Forgot your password?. No problem. You can create a new password below.",
  "Check SMS for sent Short Code.": "Check SMS for sent Short Code.",
  "Type New Password": "Type New Password",
  "New Password": "New Password",
  "Access {{placeName}}": "Access {{placeName}}",
  "Expires in {{claimBusinessLinkExpirationTime}} seconds.": "Expires in {{claimBusinessLinkExpirationTime}} seconds.",
  "Are you the Owner or an Employee at<br/>{{placeName}}?": "Are you the Owner or an Employee at<br/>{{placeName}}?",
  "Do you know someone who works at<br/>{{placeName}}?": "Do you know someone who works at<br/>{{placeName}}?",
  "Hi :-)\nI invite you to Manage “{{name}}” in Brovs for FREE. Brovs is the new website to browse for Ads, Products and Services at Local Businesses. Fashion - Food & Drink - Rewards - and more.": "Hi :-)\nI invite you to Manage “{{name}}” in Brovs for FREE. Brovs is the new website to browse for Ads, Products and Services at Local Businesses. Fashion - Food & Drink - Rewards - and more.",
  "Access Now": "Access Now",
  "Get access and Manage the Business Profile for FREE. Update business name, opening hours etc. Advertise to a large user audience to get new customers.": "Get access and Manage the Business Profile for FREE. Update business name, opening hours etc. Advertise to a large user audience to get new customers.",
  "Manage Now": "Manage Now",
  "Invite the Owner or an Employee to Manage the Business Profile for FREE. When they get access you will receive Brovs Reward Points that you can use in the Brovs Lottery.": "Invite the Owner or an Employee to Manage the Business Profile for FREE. When they get access you will receive Brovs Reward Points that you can use in the Brovs Lottery.",
  "Invite by SMS": "Invite by SMS",
  "Send Referral": "Send Referral",
  "SMS": "SMS",
  "Other": "Other",
  "Open": "Open",
  "Closes": "Closes",
  "Closed": "Closed",
  "Opens": "Opens",
  "Report a problem related to this business?": "Report a problem related to this business?",
  "We appreciate any kind of feedback that could make our service better. Contact Support to report a problem. Thank you in advance.": "We appreciate any kind of feedback that could make our service better. Contact Support to report a problem. Thank you in advance.",
  "This profile is managed by<br/>.": "This profile is managed by<br/>.",
  "Verified Profile": "Verified Profile",
  "Overview": "Overview",
  "Menu": "Menu",
  "Contact": "Contact",
  "Share": "Share",
  "Interested in Ads?": "Interested in Ads?",
  "You have expressed interest in Ads from this business.": "You have expressed interest in Ads from this business.",
  "Let Us Know": "Let Us Know",
  "Brands not added.": "Brands not added.",
  "Categories and Brands": "Categories and Brands",
  "Shopping Mall": "Shopping Mall",
  "Website": "Website",
  "Webshop": "Webshop",
  "Not added": "Not added",
  "WiFi": "WiFi",
  "Free": "Free",
  "Yes": "Yes",
  "No": "No",
  "Suitability": "Suitability",
  "Accessibility": "Accessibility",
  "Managed by": "Managed by",
  "Earn Reward Points by inviting the Owner or an Employee to manage the Business Profile.": "Earn Reward Points by inviting the Owner or an Employee to manage the Business Profile.",
  "Manage Business Profile": "Manage Business Profile",
  "Invite or Manage": "Invite or Manage",
  "Menu has not been added.": "Menu has not been added.",
  "Opening Hours": "Opening Hours",
  "Monday": "Monday",
  "Tuesday": "Tuesday",
  "Wednesday": "Wednesday",
  "Thursday": "Thursday",
  "Friday": "Friday",
  "Saturday": "Saturday",
  "Sunday": "Sunday",
  "Categories": "Categories",
  "Floor": "Floor",
  "Accept <1>User Terms of Service</1> and <3>Privacy Policy</3>.": "Accept <1>User Terms of Service</1> and <3>Privacy Policy</3>.",
  "Privacy Policy": "Privacy Policy",
  "Terms of Service": "Terms of Service",
  "Date of Birth": "Date of Birth",
  "Day": "Day",
  "Month": "Month",
  "Year": "Year",
  "Photo": "Photo",
  "Invite Friends to join Brovs": "Invite Friends to join Brovs",
  "When Invited Friends Sign's Up you will receive Reward Points that you can use in the Brovs Lottery.<br/><br/>The more Friends you Invite the more Reward Points you earn.<br/><br/>Invite as many Friends as you like.": "When Invited Friends Sign's Up you will receive Reward Points that you can use in the Brovs Lottery.<br/><br/>The more Friends you Invite the more Reward Points you earn.<br/><br/>Invite as many Friends as you like.",
  "Hi :-)\nI recommend checking out Brovs, the new website to browse for Ads, Products and Services at Local Businesses. Fashion - Food & Drink - Rewards - and more.": "Hi :-)\nI recommend checking out Brovs, the new website to browse for Ads, Products and Services at Local Businesses. Fashion - Food & Drink - Rewards - and more.",
  "BROVS": "BROVS",
  "Invite Business Owners or Employees to Manage their Business Profile for free. You will earn Reward Points when they access their Business Profile. You can Invite directly from the Business Profile.": "Invite Business Owners or Employees to Manage their Business Profile for free. You will earn Reward Points when they access their Business Profile. You can Invite directly from the Business Profile.",
  "Invite Business Owners or Employees": "Invite Business Owners or Employees",
  "Use Invite or Manage Button for Business Profile": "Use Invite or Manage Button for Business Profile",
  "Text Message / SMS": "Text Message / SMS",
  "(Only Norwegian Numbers)": "(Only Norwegian Numbers)",
  "Points": "Points",
  "You earn Rewards Points simply by Inviting Friends to use Brovs, Inviting Owners or Employees of a Business to Manage their Business Profile for free or Share Business Profiles and Ads with people you might think have an interest.": "You earn Rewards Points simply by Inviting Friends to use Brovs, Inviting Owners or Employees of a Business to Manage their Business Profile for free or Share Business Profiles and Ads with people you might think have an interest.",
  "Earn Reward Points": "Earn Reward Points",
  "Invite Friends": "Invite Friends",
  "Share Businesses": "Share Businesses",
  "Share Ads": "Share Ads",
  "Sign Up Bonus": "Sign Up Bonus",
  "Invite Friends to use Brovs and you earn Reward Points when they Sign Up as a new Brovs User. You can Invite as many Friends as you like.": "Invite Friends to use Brovs and you earn Reward Points when they Sign Up as a new Brovs User. You can Invite as many Friends as you like.",
  "Below are your total Brovs Reward Points and how you earned them. The Points can be used to participate in the Brovs Lottery.": "Below are your total Brovs Reward Points and how you earned them. The Points can be used to participate in the Brovs Lottery.",
  "My Contributions": "My Contributions",
  "Invited Friends": "Invited Friends",
  "Invited Businesses": "Invited Businesses",
  "Shared Businesses": "Shared Businesses",
  "Shared Ads": "Shared Ads",
  "Expiration of Points by 31. December": "Expiration of Points by 31. December",
  "My Rewards": "My Rewards",
  "Share Ads with people. You will earn Reward Points when Ads you shared are viewed. You can share directly from the Ad.": "Share Ads with people. You will earn Reward Points when Ads you shared are viewed. You can share directly from the Ad.",
  "Use Share Icon for Ad ": "Use Share Icon for Ad ",
  "Share Business Profiles with people. You will earn Reward Points when Business Profiles you shared are viewed. You can Share directly from the Business Profile.": "Share Business Profiles with people. You will earn Reward Points when Business Profiles you shared are viewed. You can Share directly from the Business Profile.",
  "Share Business Profiles": "Share Business Profiles",
  "Use Share Icon for Business Profile": "Use Share Icon for Business Profile",
  "You can use the earned Brovs Reward Points to get Lottery Tickets in the Brovs Lottery.<br/><br/>One Reward Point = One Lottery Ticket<br/><br/>Go To Lottery for more information.<br/><br/>Best of luck": "You can use the earned Brovs Reward Points to get Lottery Tickets in the Brovs Lottery.<br/><br/>One Reward Point = One Lottery Ticket<br/><br/>Go To Lottery for more information.<br/><br/>Best of luck",
  "Use Reward Points": "Use Reward Points",
  "Go To Lottery": "Go To Lottery",
  "Account": "Account",
  "Add your E-mail for communication and promotions.": "Add your E-mail for communication and promotions.",
  "Verified": "Verified",
  "Not Verified.": "Not Verified.",
  "Send Short Code Verification to E-mail.": "Send Short Code Verification to E-mail.",
  "Short Code Verification sent to above E-mail.": "Short Code Verification sent to above E-mail.",
  "Have you not received an E-mail with the Short Code Verification?": "Have you not received an E-mail with the Short Code Verification?",
  "Language": "Language",
  "Change Password": "Change Password",
  "Add Password": "Add Password",
  "Are you sure you want to change Password?": "Are you sure you want to change Password?",
  "Personal Information *": "Personal Information *",
  "Gender": "Gender",
  "Invalid Mobile Number.": "Invalid Mobile Number.",
  "Change if not correct.": "Change if not correct.",
  "Verified.": "Verified.",
  "Check your SMS for Short Code": "Check your SMS for Short Code",
  "Confirm Mobile Number": "Confirm Mobile Number",
  "Crop width or height is not set": "Crop width or height is not set",
  "Could not get canvas context": "Could not get canvas context",
  "Canvas is empty": "Canvas is empty",
  "Image not found.": "Image not found.",
  "Crop dimensions not set.": "Crop dimensions not set.",
  "Error decoding image.": "Error decoding image.",
  "Profile Photo": "Profile Photo",
  "Upload New": "Upload New",
  "Upload": "Upload",
  "Delete": "Delete",
  "Crop Photo": "Crop Photo",
  "Sure you want to delete?": "Sure you want to delete?",
  "Settings": "Settings",
  "Sign Out": "Sign Out",
  "Delete Account": "Delete Account",
  "Are you sure you want to delete Your Account?": "Are you sure you want to delete Your Account?",
  "Delete My Account": "Delete My Account",
  "Share Ad": "Share Ad",
  "You will receive Brovs Reward Points when the Ad is viewed by people you shared it with.<br/><br/>You can use Reward Points in the Brovs Lottery.<br/><br/>Share with as many people as you like.": "You will receive Brovs Reward Points when the Ad is viewed by people you shared it with.<br/><br/>You can use Reward Points in the Brovs Lottery.<br/><br/>Share with as many people as you like.",
  "Hi :-)\nYou might have an interest in this Ad; “{{adTitle}}” from “{{placeName}}”. View in Brovs.com, the new website to browse for Ads, Products and Services at Local Businesses. Fashion - Food & Drink - Rewards - and more.": "Hi :-)\nYou might have an interest in this Ad; “{{adTitle}}” from “{{placeName}}”. View in Brovs.com, the new website to browse for Ads, Products and Services at Local Businesses. Fashion - Food & Drink - Rewards - and more.",
  "Share With SMS": "Share With SMS",
  "Share Business Profile": "Share Business Profile",
  "You will receive Brovs Reward Points when Shared Business Profile is viewed by people you share it with.<br/><br/>You can use Reward Points in the Brovs Lottery.<br/><br/>Share with as many people as you like.": "You will receive Brovs Reward Points when Shared Business Profile is viewed by people you share it with.<br/><br/>You can use Reward Points in the Brovs Lottery.<br/><br/>Share with as many people as you like.",
  "Hi :-)\nYou might have an interest in “{{name}}”. View in Brovs, the new website to browse for Ads, Products and Services at Local Businesses. Fashion - Food & Drink - Rewards - and more.": "Hi :-)\nYou might have an interest in “{{name}}”. View in Brovs, the new website to browse for Ads, Products and Services at Local Businesses. Fashion - Food & Drink - Rewards - and more.",
  "Manage Business": "Manage Business",
  "Rewards": "Rewards",
  "Business": "Business",
  "Easy and FREE Sign In": "Easy and FREE Sign In",
  "Brovs is a website where you can browse Ads, Products and Services at Local Businesses.<br/><br/>As Owner or Employee you get access to the Brovs Business Platform so you can Manage the Business Profile for FREE.": "Brovs is a website where you can browse Ads, Products and Services at Local Businesses.<br/><br/>As Owner or Employee you get access to the Brovs Business Platform so you can Manage the Business Profile for FREE.",
  "thinks you have an interest in<br/>.": "thinks you have an interest in<br/>.",
  "Brovs is a website where you can browse Ads, Products and Services at Local Businesses.<br/><br/>You earn Reward Points by Signing Up or Viewing the Business Profile.": "Brovs is a website where you can browse Ads, Products and Services at Local Businesses.<br/><br/>You earn Reward Points by Signing Up or Viewing the Business Profile.",
  "Browse ads, products and<br/>services at local businesses": "Browse ads, products and<br/>services at local businesses",
  "invites you to manage {{businessName}}.": "invites you to manage {{businessName}}.",
  "thinks you have an interest in this Ad": "thinks you have an interest in this Ad",
  "Fashion": "Fashion",
  "Food & Drink": "Food & Drink",
  "thinks you have an interest in using Brovs": "thinks you have an interest in using Brovs",
  "Sign In now to receive Reward Points and browse Ads, Products and Services at Local Businesses.": "Sign In now to receive Reward Points and browse Ads, Products and Services at Local Businesses."
}
