import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { ConsumerApi } from "../ConsumerApi.dto";
import DisplayPoints from "./DisplayPoints";

type BusinessReferralsProps = {
  rewardsStatus: ConsumerApi.GetRewardsStatusResponse;
};

const BusinessReferrals: React.FC<BusinessReferralsProps> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <DisplayPoints
        title={t("Invite Business Owners or Employees")}
        description={
          <Trans>
            Invite Business Owners or Employees to Manage their Business Profile
            for free. You will earn Reward Points when they access their
            Business Profile. You can Invite directly from the Business Profile.
          </Trans>
        }
        points={props.rewardsStatus.referBusiness.points}
        helpText={t("Use Invite or Manage Button for Business Profile")}
        helpExtra={
          <div className="text-small-bold h-[30px] px-[35px] flex justify-center items-center border">
            {t("Invite or Manage")}
          </div>
        }
      />
    </>
  );
};

export default BusinessReferrals;
