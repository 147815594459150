import { loadingState, apiServiceState } from "../atoms";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { ConsumerApi } from "../ConsumerApi.dto";
import { sendSMS } from "../helpers/ShareHelper";
import { Overlay } from "../overlay";

type PlaceReferProps = {
  place: ConsumerApi.PlaceBasicDto;
  onClose: () => void;
};

export const ReferPlace: React.FC<PlaceReferProps> = (props) => {
  const { t } = useTranslation();

  const setLoading = useSetRecoilState(loadingState);
  const apiService = useRecoilValue(apiServiceState);

  const sendReferral = useCallback(
    (sms?: boolean) => {
      setLoading(true);

      apiService
        .get(
          new ConsumerApi.ReferBusinessLinkRequest({
            placeId: props.place.id,
          }),
        )
        .then((res) => {
          const message = t(
            `Hi :-)\nI invite you to Manage “{{name}}” in Brovs for FREE. Brovs is the new website to browse for Ads, Products and Services at Local Businesses. Fashion - Food & Drink - Rewards - and more.`,
            { name: props.place.name },
          );
          const url = res.linkWithReferBusinessToken;

          if (sms) {
            sendSMS(`${message}\n${url}`);
          } else {
            navigator.share({
              title: props.place.name,
              text: message,
              url: url,
            });
          }
          props.onClose();
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    },
    [apiService, props, setLoading, t],
  );

  return (
    <Overlay onClose={props.onClose}>
      <div className="flex flex-col items-center">
        <div>
          <svg
            width="58"
            height="58"
            viewBox="0 0 58 58"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="29" cy="29" r="28" stroke="#BDBDBD" strokeWidth="2" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M39 26.8397L32.0791 20V23.9774C24.2932 24.038 18 30.2903 18 38C18 38 21.0691 29.5674 32.0791 29.5068V33.6785L39 26.8397Z"
              fill="#262525"
            />
          </svg>
        </div>
        <div style={{ marginTop: "25px" }} className="font-bold text-20">
          {t("Send Referral")}
        </div>
        <button
          onClick={() => sendReferral(true)}
          style={{ marginTop: "25px" }}
          className="bg-blue text-white style-subtitle h-11 w-full rounded"
        >
          {t("SMS")}
        </button>
        {/* TODO: Hide until we have tested and fixed the preview in Twitter, FB and other media. */}
        {/* {navigator.share !== undefined ?
          <button
            onClick={() => sendReferral()}
            style={{marginTop: "15px"}}
            className="bg-blue text-white style-subtitle h-11 w-full rounded">
            {t("Other")}
          </button>
        : null} */}
      </div>
    </Overlay>
  );
};
