import { apiServiceState, loadingState } from "../atoms";
import { ConsumerApi } from "../ConsumerApi.dto";
import React, { useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";
import { useRecoilValue } from "recoil";
import { Overlay } from "../overlay";
import { Button } from "../button";
import { setTimeout } from "timers";
import { sendSMS } from "../helpers/ShareHelper";

type PlaceClaimProps = {
  place: ConsumerApi.PlaceBasicDto;
  onClose: () => void;
  isReferring?: boolean;
};

export const PlaceClaim: React.FC<PlaceClaimProps> = (props) => {
  const { t } = useTranslation();
  const setLoading = useSetRecoilState(loadingState);
  const apiService = useRecoilValue(apiServiceState);
  const placeName = props.place.name;

  const [claimBusinessLink, setClaimBusinessLink] = useState<string>();
  const [claimBusinessLinkExpirationTime, setClaimBusinessLinkExpirationTime] =
    useState(59);

  const claimThisBusiness = useCallback(() => {
    setLoading(true);
    apiService
      .get(
        new ConsumerApi.GetBusinessWebSignInLinkRequest({
          claimPlaceGuid: props.place.guid,
        }),
      )
      .then((response) =>
        setClaimBusinessLink(response.timeLimitedBusinessWebUrlWithTokens),
      )
      .finally(() => setLoading(false));
  }, [apiService, props, setLoading]);

  const sendReferral = useCallback(
    (sms?: boolean) => {
      setLoading(true);

      apiService
        .get(
          new ConsumerApi.ReferBusinessLinkRequest({
            placeId: props.place.id,
          }),
        )
        .then((res) => {
          const message = t(
            `Hi :-)\nI invite you to Manage “{{name}}” in Brovs for FREE. Brovs is the new website to browse for Ads, Products and Services at Local Businesses. Fashion - Food & Drink - Rewards - and more.`,
            { name: props.place.name },
          );
          const url = res.linkWithReferBusinessToken;

          if (sms) {
            sendSMS(`${message}\n${url}`);
          } else {
            navigator.share({
              title: props.place.name,
              text: message,
              url: url,
            });
          }
          props.onClose();
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    },
    [apiService, props, setLoading, t],
  );

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (claimBusinessLink) {
      if (claimBusinessLinkExpirationTime > 0) {
        timeout = setTimeout(
          () => setClaimBusinessLinkExpirationTime((v) => v - 1),
          1000,
        );
      } else {
        props.onClose();
      }
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [claimBusinessLink, claimBusinessLinkExpirationTime]);

  return (
    <Overlay onClose={props.onClose}>
      <div className="flex flex-col items-center">
        <div>
          <svg
            width="58"
            height="58"
            viewBox="0 0 58 58"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M57 29C57 44.464 44.464 57 29 57C13.536 57 1 44.464 1 29C1 13.536 13.536 1 29 1C44.464 1 57 13.536 57 29Z"
              stroke="#D2D2D2"
              strokeWidth="2"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M39.625 16H19.375L16 23.5H43L39.625 16ZM16 25H43V41.5H16V25ZM31.75 29.4H19.375V38.2H31.75V29.4ZM39.625 29.4H35.125V38.2H39.625V29.4Z"
              fill="currentColor"
            />
          </svg>
        </div>
        {claimBusinessLink ? (
          <>
            <div className="font-bold text-20 text-center max-w-280px line-clamp-3 mt-[25px]">
              <Trans>Access {{ placeName }}</Trans>
            </div>
            <div className="center mt-[15px] style-body-copy">
              <Trans>
                Expires in {{ claimBusinessLinkExpirationTime }} seconds.
              </Trans>
            </div>
            <div className="mt-[25px] w-full">
              <Button
                onClick={() => {
                  setClaimBusinessLink(undefined);
                  props.onClose();
                }}
                type="externalLink"
                href={claimBusinessLink}
                title={t("Access Now")}
                target="_blank"
              />
            </div>
          </>
        ) : (
          <>
            <div className="font-bold text-20 text-center mt-[25px]">
              <Trans>
                Are you the Owner or an Employee at
                <br />
                {{ placeName }}?
              </Trans>
            </div>
            <div className="center mt-15px style-body-copy">
              {t(
                "Get access and Manage the Business Profile for FREE. Update business name, opening hours etc. Advertise to a large user audience to get new customers.",
              )}
            </div>
            <div className="mt-25px w-full">
              <Button onClick={claimThisBusiness} title={t("Manage Now")} />
            </div>
            {!props.isReferring && (
              <>
                <div className="border-t mt-[30px] pt-[30px] flex justify-center w-full">
                  <svg
                    width="58"
                    height="58"
                    viewBox="0 0 58 58"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="29"
                      cy="29"
                      r="28"
                      stroke="#BDBDBD"
                      strokeWidth="2"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M39 26.8397L32.0791 20V23.9774C24.2932 24.038 18 30.2903 18 38C18 38 21.0691 29.5674 32.0791 29.5068V33.6785L39 26.8397Z"
                      fill="#262525"
                    />
                  </svg>
                </div>
                <div className="mt-25px">
                  <div className="font-bold text-20 text-center line-clamp-3">
                    <Trans>
                      Do you know someone who works at
                      <br />
                      {{ placeName }}?
                    </Trans>
                  </div>
                </div>
                <div className="center mt-[15px] style-body-copy">
                  {t(
                    "Invite the Owner or an Employee to Manage the Business Profile for FREE. When they get access you will receive Brovs Reward Points that you can use in the Brovs Lottery.",
                  )}
                </div>
                <div className="mt-[25px] w-full">
                  <Button
                    onClick={() => {
                      sendReferral(true);
                      props.onClose();
                    }}
                    title={t("Invite by SMS")}
                  />
                </div>
              </>
            )}
          </>
        )}
      </div>
    </Overlay>
  );
};
